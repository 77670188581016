@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Poppins, Arial, system-ui, sans-serif;
        font-display: swap;
    }

    /* Write your own custom base styles here */
    h1,
    .h1 {
        @apply text-3xl font-bold md:text-5xl;
    }

    h2,
    .h2 {
        @apply text-2xl font-bold md:text-4xl;
    }

    .section > h2,
    .section-without-padding > h2,
    .section > .h2,
    .section-without-padding > .h2 {
        @apply font-semibold text-lg sm:text-2xl x:text-4xl text-textPrimary lg:h-[10.375rem] h-[7.5rem] grid text-center content-center;
    }

    h3,
    .h3 {
        @apply text-xl font-bold md:text-3xl;
    }

    h4,
    .h4 {
        @apply text-lg font-bold;
    }

    body {
        @apply text-sm md:text-base;
    }

    .page-wrapper {
        @apply max-w-[1440px] mx-auto;
    }

    .layout {
        @apply px-[35px] lg:px-[125px];
    }
    
    .layout__navbar {
        @apply px-[24px] lg:px-[94px];
    }

    .section {
        @apply px-[30px] lg:px-[125px];
    }

    .scroll-up {
        @apply sticky top-0 z-10;
        @apply transition duration-700 ease-in;
    }

    .scroll-down {
        @apply opacity-0;
    }
    
    .hyphenateManual {
        -webkit-hyphens: manual;
        -ms-hyphens: manual;
        hyphens: manual;
    }

    .hyphenate {
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }
    
    div.blog-article a:hover{
        font-weight: 900;
    }
}

@layer utilities {
    .blog__bg {
        background: #e6edff;
    }
    .blog__news_letter {
        box-shadow: 31px 43px 21px rgba(0, 29, 102, 0.01), 17px 24px 18px rgba(0, 29, 102, 0.03),
            8px 11px 13px rgba(0, 29, 102, 0.05), 2px 3px 7px rgba(0, 29, 102, 0.06),
            0px 0px 0px rgba(0, 29, 102, 0.06);
        border-radius: 20px;
    }
    .post__card {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        /* new shadow */

        box-shadow: 31px 43px 21px rgba(0, 29, 102, 0.01), 17px 24px 18px rgba(0, 29, 102, 0.03),
            8px 11px 13px rgba(0, 29, 102, 0.05), 2px 3px 7px rgba(0, 29, 102, 0.06),
            0px 0px 0px rgba(0, 29, 102, 0.06);
    }
    .animated-underline {
        background-image: linear-gradient(#33333300, #33333300),
            linear-gradient(to right, #00e0f3, #00c4fd);
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.3s ease;
    }
    .animated-underline:hover,
    .animated-underline:focus {
        background-size: 0 2px, 100% 2px;
    }
    .howwework__card {
        box-shadow: 31px 43px 21px rgba(0, 29, 102, 0.01), 17px 24px 18px rgba(0, 29, 102, 0.03),
            8px 11px 13px rgba(0, 29, 102, 0.05), 2px 3px 7px rgba(0, 29, 102, 0.06),
            0px 0px 0px rgba(0, 29, 102, 0.06);
    }
    .primaryCTA {
        background: -webkit-linear-gradient(bottom, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: -o-linear-gradient(bottom, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: -ms-linear-gradient(bottom, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: -moz-linear-gradient(bottom, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: linear-gradient(to top, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
    }

    .secondaryCTA {
        background: -webkit-linear-gradient(271deg, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: -o-linear-gradient(271deg, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: -ms-linear-gradient(271deg, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: -moz-linear-gradient(271deg, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
        background: linear-gradient(179deg, rgb(255, 255, 255) 0%, rgb(229, 237, 255) 100%);
    }
    .why__we__card {
        background: #dee8ff;
        box-shadow: 31px 43px 21px rgba(0, 29, 102, 0.01), 17px 24px 18px rgba(0, 29, 102, 0.03),
            8px 11px 13px rgba(0, 29, 102, 0.05), 2px 3px 7px rgba(0, 29, 102, 0.06),
            0px 0px 0px rgba(0, 29, 102, 0.06);
    }
    .product__card {
        background: #e6edff;
        box-shadow: 31px 43px 21px rgba(0, 29, 102, 0.01), 17px 24px 18px rgba(0, 29, 102, 0.03),
            8px 11px 13px rgba(0, 29, 102, 0.05), 2px 3px 7px rgba(0, 29, 102, 0.06),
            0px 0px 0px rgba(0, 29, 102, 0.06);
    }
    .calendly__width {
        width: 99.9% !important;
    }
    .wd__bg {
        background: linear-gradient(180deg, #e5edff 0%, #ffffff 100%);
    }
    .wd__bg_secondary {
        background: linear-gradient(180deg, #ffffff 0%, #e5edff 100%);
    }
    .hero::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 1;
    }

    @media only screen and (max-width: 976px) {
        html {
            scroll-padding-top: 0;
        }
        .about__us {
            background-image: url('../assets/images/main/aboutUsNic.webp');
            background-repeat: no-repeat;
        }
        .aboutus__detail__onLarge {
            display: none;
        }
        .cookies__onSmall {
            display: block;
        }
        .cookies__onLarge {
            display: none;
        }
    }
    @media only screen and (min-width: 976px) {
        .about__us {
            background: url('../assets/images/main/aboutUsNic.webp') center no-repeat;
            background-size: contain;
        }
        .aboutus__detail__onSmall {
            display: none;
        }
        .cookies__onSmall {
            display: none;
        }
        .cookies__onLarge {
            display: block;
        }
    }
    .loading-skeleton{
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }
}
