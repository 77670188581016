@media screen and (max-width: 1000px) {
    .calendly-inline-widget {
        height: 1100px !important;
    }
}
@media screen and (min-width: 1250px) {
    .calendly-contact-us > .calendly-inline-widget {
        height: 700px !important;
    }
}
@media screen and (min-width: 1001px) and (max-width: 1023px) {
    .calendly-contact-us > .calendly-inline-widget {
        height: 800px !important;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1249px) {
    .calendly-contact-us > .calendly-inline-widget {
        height: 1100px !important;
    }
}
@media screen and (min-width: 650px) {
    .calendly-modal > .calendly-inline-widget {
        height: 1070px !important;
    }
}
